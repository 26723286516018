import React, { useEffect, useState } from 'react';
import { DefaultButton } from '@fluentui/react';
import { localize } from 'src/l10n';
import { Label, UnstyledButton } from 'src/ui';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import "./CalypsoPromoBanner.scss";
import api from 'src/spintr/SpintrApi';

interface IProps {
    isAdmin: boolean;
    isEditor: boolean;
    appMode: boolean;
    betaEnabled?: boolean;
};

const CalypsoPromoBanner = (props: IProps) => {
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [localStorageId] = useState<string>("spintr_hasSeenPromoBanner2");

    useEffect(() =>  {
        const hasSeenPromoBanner = localStorage.getItem(localStorageId) === "true";

        if ((props.isAdmin || props.isEditor) && !props.appMode && !hasSeenPromoBanner) {
            setShowBanner(true);
        }
    }, [props.isAdmin, props.isEditor]);

    if (!showBanner) {
        return null;
    }

    return (
        <div className="CalypsoPromoBanner">
            <div className="CalypsoPromoBanner-inner">
                <div className="main">
                    <div className="icon-wrapper">
                        <ReactSVG src={"/images/SomethingFun_party.svg"} />
                    </div>
                    <div className="content">
                        <Label size="body-1" color="white" weight="medium">{localize("PROMO_TITLE")}</Label>
                        <Label size="body-2" color="white">{localize("PROMO_MESSAGE")}</Label>
                    </div>
                </div>
                <div className="buttons">
                    <UnstyledButton onClick={() => {
                        setShowBanner(false);
                        localStorage.setItem(localStorageId, "true");
                    }}>
                        <Label color="white">{localize("Stang")}</Label>
                    </UnstyledButton>
                    {/* <DefaultButton onClick={this.closeBanner}>{localize("Stang")}</DefaultButton> */}
                    <DefaultButton onClick={() => {
                        window.open("https://www.spintr.com/calypso", "_blank");
                    }}>{localize("OPEN_WEBINAR")}</DefaultButton>
                    {props.isAdmin && props.betaEnabled && (
                        <DefaultButton onClick={() => {
                            api.get("/api/instances/visit-beta").then((response) => {
                                if (!!response &&
                                    !!response.data) {
                                    window.open(response.data, "_blank");
                                }
                            }).catch(() => { });
                        }}>{localize("VISIT_BETA_VERSION")}</DefaultButton>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        appMode: state.ui.appMode,
        betaEnabled: state.instance.get("betaEnabled"),
    };
};

export default connect(mapStateToProps)(CalypsoPromoBanner);
